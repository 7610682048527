<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import api from '@/services/secureHttps';
import Modal from 'bootstrap/js/dist/modal';
import { mapGetters } from 'vuex';

export default {
    components: {
        Layout,
        PageHeader
    },

    mounted() {
        this.GetAppsInStore();
    },

    props: {
        title: String
    },

    data() {
        return {
            //  title: "App Store",
            storeData: null,
            modalDataObj: null,
            uninstalModal: null
        };
    },

    computed: {
        ...mapGetters('context', [
            'selectedWebisteId', 'selectedOrganisationId'
        ])
    },

    watch: {
        selectedWebisteId: function () {
            console.log("updating info!!!");
            this.GetAppsInStore();
        }
    },

    methods: {
        GetAppsInStore() {
            api.getAllStoreApps(this.selectedOrganisationId)
                .then(response => {
                    console.log("GetAppsinstore", response);
                    this.storeData = response.data;
                });
        },

        installApp(appId) {
            console.log("install app", appId, this.selectedOrganisationId, this.selectedWebisteId);
            api.getAppInfo(appId, this.selectedOrganisationId, this.selectedWebisteId)
                .then(response => {
                    console.log("store", response)
                    window.open(response.data.installUrl, '_blank');
                })
        },

        confirmUninstallApp(appDataObj) {
            this.modalDataObj = appDataObj;
            var modalEl = document.getElementById("confirmUninstallAppModal");
            this.uninstalModal = new Modal(modalEl);
            this.uninstalModal.show();
        },

        uninstallApp() {
            if (this.modalDataObj === null) {
                return;
            }

            api.uninstallApp(this.modalDataObj.id, this.selectedOrganisationId, this.selectedWebisteId)
                .then(() => {
                    this.uninstalModal.hide();
                    this.modalDataObj = null;
                    this.uninstalModal = null;
                    this.GetAppsInStore();
                });
        }
    }
}
</script>
        
<template>
    <Layout>
        <PageHeader :title="title" />
        <div class="row">
            <div class="col">
                <div class="h-100">
                    <div class="row mb-3 pb-1">
                        <h3>App Integrations</h3>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="storeData" class="row">
            <div class="col">
                <div class="h-100">
                    <div v-for="vendor in storeData.vendors" :key="vendor.vendorId" class="row mb-3 pb-1">

                        <!-- {{ vendor.vendorName }} -->

                        <div v-for="app in vendor.apps" :key="app.id">
                            <div class="card border-dark mb-3" style="max-width: 18rem;">
                                <div class="card-header text-center"> {{ app.name }}</div>
                                <div class="card-body text-dark text-center">
                                    <a v-if="app.isInstalled === false" @click="installApp(app.id)" href="#">Integrate
                                        app</a>
                                    <div v-else>Allready installed

                                        <div class="text-center">
                                            <button type="button" class="btn btn-soft-primary btn-sm"
                                                @click="confirmUninstallApp(app)">Uninstall</button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </div>


        <!--start confirm uninstall dialogue-->

        <div id="confirmUninstallAppModal" class="modal fade" tabindex="-1" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div v-if="modalDataObj">
                            Confirm you would like to uninstall {{ modalDataObj.name }}
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-primary" @click="uninstallApp">UninstallApp</button>
                    </div>
                </div>
            </div>
            <!-- end confirm uninstall dialogue-->
        </div>


    </Layout>
</template>